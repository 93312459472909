<script setup>
import { createBannerEvent } from "./../utils/pixel.js";

function requestForProductInfoWhatsApp() {
  createBannerEvent("Bolsos", "Footer", "Banner bolsos");
  const message = `https://api.whatsapp.com/send?phone=50685833182&text=Hola, me gustaría realizar una`;
  window.open(message, "_blank");
}
// function requestForProductInfoWhatsAppNewCollection() {
//   const message = `https://api.whatsapp.com/send?phone=50685833182&text=Hola, quisiera ver los estilos de la nueva colección`;
//   window.open(message, "_blank");
// }
</script>

<template>
  <div class="ps-section masonry-root">
    <div class="ps-container">
      <div class="ps-section__header mb-50">
        <h3 class="ps-section__title" data-mask="Sandalias">- Sandalias</h3>
      </div>
      <div class="ps-section__content pb-50">
        <div
          class="masonry-wrapper"
          data-col-md="3"
          data-col-sm="2"
          data-col-xs="1"
          data-gap="30"
          data-radio="100%"
        >
          <div class="ps-masonry">
            <div class="grid-sizer"></div>
            <div class="grid-item high">
              <div class="grid-item__content-wrapper">
                <a
                  v-on:click="requestForProductInfoWhatsApp()"
                  class="ps-offer"
                >
                  <img
                    src="https://geneticmisc.s3.amazonaws.com/dastefi/2024/footer/stefa_atenea_black-min.jpg"
                  />
                </a>
              </div>
            </div>
            <div class="grid-item high">
              <div class="grid-item__content-wrapper">
                <a class="ps-offer">
                  <img src="/images/sandals/quote-min.jpg" alt="Quote" />
                </a>
              </div>
            </div>
            <div class="grid-item high">
              <div class="grid-item__content-wrapper">
                <a
                  v-on:click="requestForProductInfoWhatsApp()"
                  class="ps-offer"
                >
                  <img
                    src="https://geneticmisc.s3.amazonaws.com/dastefi/2024/footer/stefa_footer_right-min.jpg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
