export function createButtonEvent() {}

export function createBannerEvent(productName, section, button) {
  window.sendTrack({
    event_name: "PreguntarInfoWhatsApp",
    test_event_code: "BannerEvent",
    section: section,
    style: productName,
    button: button,
  });
}

export function createSubscribeEvent() {}
