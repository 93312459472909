<script setup>
function subscribeToNews() {
  const message = `https://api.whatsapp.com/send?phone=50685833182&text=Hola, Me gustaría recibir noticias de las nuevas colecciones`;
  window.open(message, "_blank");
}
</script>
<template>
  <div class="ps-subscribe">
    <div class="ps-container">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <h3>
            <i class="fa fa-envelope"></i>Recibe catálogos y noticias de las
            últimas colecciones
          </h3>
        </div>
        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12">
          <div class="ps-subscribe__form">
            <input
              class="form-control"
              type="text"
              value="Me gustaría recibir noticias de las nuevas colecciones"
            />
            <button @click="subscribeToNews()">Suscribirme</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
