<script setup>
import Carousel from "../components/Carousel.vue";
import Catalog from "../components/Catalog.vue";
import Promo from "../components/Promo.vue";
import Sandals from "../components/Sandals.vue";
import Subscribe from "../components/Subscribe.vue";
import { computed } from "vue";

const isSubscribeSectionEnabled = computed(() => {
  const display = process.env.VUE_APP_SHOW_SUBSCRIBE == "true";
  return display;
});
</script>
<template>
  <Carousel />
  <Catalog classes="nuevas" title="Nuevas" data-mask="Nuevas" product="shoes" />
  <Catalog classes="bolsos" title="Bolsos" data-mask="Bolsos" product="bags" />
  <!-- <Catalog classes="ultimos" title="Últimos pares" data-mask="Últimos pares" />
  <Catalog classes="regresan" title="Regresan" data-mask="Regresan" /> -->
  <Sandals />
  <Promo />
  <Subscribe v-if="isSubscribeSectionEnabled" />
</template>
