<script setup></script>
<style>
.terms_and_conditions {
  margin: 25px;
  max-width: 1400px;
}
h2,
h4 {
  margin-bottom: 10px;
}
</style>
<template>
  <div class="terms_and_conditions">
    <h2>Términos de uso</h2>

    <p>
      El sitio web www.calzadodastefi.com, pertenecen a una plataforma
      administrada por Calzado Da Stefi, al realizar compras en este sitio web
      usted acepta los siguientes términos y condiciones, por favor leerlas
      cuidadosamente. De no aceptar las siguientes condiciones no utilice este
      sitio web.
    </p>

    <p>
      Para ingresar a los sitios web y realizar cualquier compra es obligatorio
      ser mayor de edad.
    </p>

    <p>
      Al ingresar en estos sitios usted reconoce y acepta que los usa de manera
      responsable y bajo su propio riesgo, de manera que ninguna de las partes
      involucradas para crear, producir o entregar este sitio es responsable de
      ningún daño directo, incidental, consecuente, indirecto o punitivo ni de
      otras pérdidas.
    </p>

    <h2>Términos de compra</h2>

    <p>
      En relación con la oferta y venta de productos que usted puede tener
      acceso al ingresar y utilizar la web antes mencionadas, estas son las
      condiciones generales que aplicarán a dichas actividades (oferta y venta):
    </p>

    <p>
      Plataforma: el sitio web antes mencionado utilizará de forma confidencial
      los datos registrados por cada comprador, utilizándolos única y
      exclusivamente para completar las ventas dentro de la plataforma.
    </p>

    <p>
      Artículos: los productos que puede adquirir a través del sitio web
      https://www.calzadodastefi.com/, corresponden a calzado, esta lista y tipo
      de productos pueden ser variados, ampliados o reducidos.
    </p>

    <p>
      Precios: los precios de los artículos publicados en www.calzadodastefi.com
      estarán sujeto a cambios sin previo aviso.
    </p>

    <p>
      Promociones: Los productos con descuento publicados en la plataforma web
      www.calzadodastefi.com, son válidos únicamente para compras en la
      plataforma o de manera virtual vía Whatsapp, Facebook o Instagram.
    </p>

    <p>
      Envíos: Contamos con dos medios de entregas: Correos de Costa Rica y
      entrega directa para las zonas de Curridabat, Zapote y San Pedro de Montes
      de Oca.
    </p>

    <h2>Fallas en sistema</h2>

    <p>
      El USUARIO reconoce que pueden existir interrupciones en los sitios web
      debido a circunstancias, tanto dentro como fuera del control de Calzado Da
      Stefi
    </p>

    <p>
      En caso de problemas técnicos, fallas en el sistema o en el servicio de
      internet, Calzado Da Stefi no garantizará el acceso y uso continuo o
      ininterrumpido de los sitios mencionados, no obstante, realizará esfuerzos
      razonables para mantenerse activo.
    </p>

    <p>
      Calzado Da Stefi tampoco será responsable por cualquier virus que pudiera
      infectar el equipo del USUARIO como consecuencia del acceso y/o uso del
      mismo, lo anterior a raíz de cualquier transferencia de datos, archivos,
      imágenes, textos o cualquier uso indebido de la información mostrada en
      Calzado Da Stefi
    </p>

    <p>
      El USUARIO no podrá imputar responsabilidad alguna ni exigir a Calzado Da
      Stefi el pago por lucro cesante o daño emergente, en virtud de perjuicios
      resultantes de dificultades técnicas o fallas en los sistemas o en
      Internet.
    </p>

    <h2>Métodos de pago</h2>

    <p>
      En Calzado Da Stefi recibimos pagos vía Sinpe Móvil o vía transferencia
      bancaria (Cuenta o número de Sinpe Móvil a solicitar al 8583-3182, el cual
      corresponde al número de la tienda)
    </p>

    <p>
      Debe reportar el comprobante de pago, enviando una copia al correo
      electrónico: calzadodastefi@gmail.com o a nuestro WhatsApp: 8583-3182; así
      los encargados pueden confirmar el pedido para ser procesado.
    </p>

    <h2>Políticas de Envíos</h2>

    <h4>Compañías de entregas: Correos de Costa Rica y Mensajero Privado</h4>

    <p>
      Al realizar la compra en nuestro sitio web www.calzadodastefi.com, usted
      acepta que la empresa Correos de Costa Rica y/o Mensajería Privada sea la
      que se encargue de realizar la entrega de este producto, la Compañía
      Calzado Da Stefi se compromete a brindarle mediante mensaje de WhatsApp el
      número de guía con el cual usted podrá dar seguimiento a la ubicación de
      su compra.
    </p>

    <h2>Garantías y devoluciones</h2>

    <p>
      La garantía otorgada para los artículos es de 30 días hábiles a partir de
      la entrega de la compra. Ante cualquier solicitud de garantía, la compañía
      Calzado Da Stefi procederá de la siguiente manera: sustitución o cambio de
      producto sujeto a diferencia de precio, sujeto a existencia en inventario.
    </p>

    <h4>¿Cómo tramitar un reclamo por garantía?</h4>

    <p>
      Si usted requiere hacer uso de la garantía, debe traer por su cuenta y
      costo el artículo a nuestras bodegas en San Pedro de Montes de Oca, a fin
      de analizar la procedencia del reclamo, el administrador de la bodega
      recibirá el artículo y confeccionará una boleta de estudio de garantía con
      la cual enviará el articulo a oficinas centrales para su análisis. La
      garantía es únicamente por desperfectos de fábrica y deberá de presentar
      la factura de la compra. Tiene 30 días posterior a recibir el pedido
    </p>

    <h4>
      ¿Qué sucede si vivo fuera del Área Metropolitana y no tengo una tienda
      cerca para tramitar mi garantía?
    </h4>

    <p>
      Puede envíar el artículo a través de Correos de Costa Rica y el costo de
      envío es asumido por el o la compradora
    </p>

    <h4>¿Qué sucede si recibo un producto que no fue el que pedí?</h4>

    <p>
      Calzado Da Stefi se hará responsable del envío de vuelta, coordinando con
      el o la compradora para el reintegro del monto, luego de ser enviado el
      comprabante y guía de Correos De Costa Rica
    </p>
  </div>
</template>
