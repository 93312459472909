<template>
  <div id="app">
    <transition name="modal">
      <div v-if="showModal" class="modal">
        <button @click="showModal = false" class="close-btn">X</button>
        <p
          class="modal-text"
          style="color: white; font-size: 1em; text-align: center"
        >
          La promo más grande del año en Calzado Da Stefi
        </p>
        <span style="color: yellow">
          <img
            src="/images/icons/hot-sale.png"
            alt="Icon"
            class="icon"
            height="10"
            width="10"
          />
          <img
            src="/images/icons/hot-sale.png"
            alt="Icon"
            class="icon"
            height="10"
            width="10"
          />
          <img
            src="/images/icons/hot-sale.png"
            alt="Icon"
            class="icon"
            height="10"
            width="10"
          />
        </span>
        <p
          class="modal-text"
          style="
            color: white;
            font-size: 1em;
            text-align: center;
            margin-top: 20px;
          "
        >
          ¡No te la pierdas! Solo esta semana
        </p>
      </div>
    </transition>
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      showModal: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.showModal = false;
    }, 3000);
  },
};
</script>

<style>
.modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: red;
  display: flex;
  flex-direction: column; /* Add this line */
  justify-content: center;
  align-items: center;
  color: white;
  padding: 20px;
  font-size: 2em;
  opacity: 0.9;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: white;
  font-size: 1em;
  cursor: pointer;
}

.icon {
  width: 50px;
  height: 50px;
  margin-top: 20px; /* Add some space above the icon */
}

.promo-btn {
  background-color: white;
  color: red;
  border: none;
  padding: 10px 20px;
  margin-top: 20px; /* Add some space above the button */
  cursor: pointer;
  font-size: 1em;
  border-radius: 5px;
}
</style>
