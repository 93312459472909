<script setup>
import { ref, computed, defineProps, onMounted } from "vue";
import productsData from "../products.json";
import Product from "./Product.vue";
import axios from "axios";
import shoesFallback from "../shoes_fallback.json";
import bagsFallback from "../bags_fallback.json";

const props = defineProps({
  classes: {
    type: String,
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  dataMask: {
    type: String,
    default: "Estilos",
  },
  product: {
    type: String,
  },
});

// const products = ref(getActiveProducts());
const apiProducts = ref([]);
const loading = ref(true);
const excludedStyles = ["afrodita", "aurora_black", "cami_silver"];

// function getActiveProducts() {
//   let products = productsData.filter((p) => p.status);
//   if (props.classes !== "") {
//     const classes = props.classes.split(",");
//     products = products.filter((p) => classes.includes(p.class));
//   }
//   return products;
// }

const getTotalStylesByCategory = computed(() => {
  const products = productsData;
  const countMap = new Map();
  products.forEach((product) => {
    const productClass = "." + product.class;
    countMap.set(productClass, (countMap.get(productClass) || 0) + 1);
  });
  const groupWithQuantities = Array.from(countMap.entries());
  return groupWithQuantities;
});

const loadProducts = async () => {
  let productsResult = [];
  let filteredProducts = [];
  if (process.env.VUE_APP_USE_FALLBACK) {
    if (props.product == "shoes") {
      productsResult = shoesFallback.reverse();
      productsResult.sort((a, b) => {
        // Check if each product is deluxe or new_collection, assigning a higher score to deluxe
        const scoreA = (a.new_collection ? 2 : 0) + (a.deluxe ? 1 : 0);
        const scoreB = (b.new_collection ? 2 : 0) + (b.deluxe ? 1 : 0);

        // Sort by score in descending order
        return scoreB - scoreA;
      });
    }
    if (props.product == "bags") {
      productsResult = bagsFallback.reverse();
    }
    filteredProducts = productsResult.filter((product) => {
      if (
        !excludedStyles.includes(product?.reference?.reference) &&
        product.status === "active"
      ) {
        return product;
      }
    });
  } else {
    const username = process.env.VUE_APP_ALEGRA_USER;
    const password = process.env.VUE_APP_ALEGRA_PWD;

    const base64Credentials = btoa(`${username}:${password}`);

    const options = {
      method: "GET",
      headers: {
        accept: "application/json",
        authorization: `Basic ${base64Credentials}`,
      },
      crossDomain: true,
    };

    if (props.product == "shoes") {
      productsResult = await axios(
        "https://api.alegra.com/api/v1/items?type=variantParent",
        options
      );
    }
    if (props.product == "bags") {
      productsResult = await axios(
        "https://api.alegra.com/api/v1/items?idItemCategory=2",
        options
      );
    }
    filteredProducts = productsResult.data.filter((product) => {
      if (
        !excludedStyles.includes(product?.reference?.reference) &&
        product.status === "active"
      ) {
        return product;
      }
    });
  }

  return filteredProducts;
};

onMounted(async () => {
  apiProducts.value = await loadProducts();
  loading.value = false;
});
</script>

<template>
  <div class="ps-section--features-product ps-section masonry-root">
    <div class="ps-container">
      <div class="ps-section__header mb-50">
        <h3 class="ps-section__title" :data-mask="props.dataMask">
          - {{ props.title }}
        </h3>
        <p>
          Encuentra tu estilo favorito en tu talla, en caso no tenerlo
          disponible, podés inscribirte para el próximo pedido
        </p>
        <p>
          <span style="color: black; font-weight: bold"
            >Términos y Condiciones: Artículos en promoción no cuentan con
            garantía.</span
          >
        </p>
        <ul class="ps-masonry__filter" v-if="1 === 2">
          <li v-for="product in getTotalStylesByCategory" :key="product.name">
            <a href="#" :data-filter="`${product[0]}`"
              >{{ product[0].slice(1) }} <sup>{{ product[1] }}</sup></a
            >
          </li>
          <li class="current">
            <a href="#" data-filter="*">Ver Todos <sup>20</sup></a>
          </li>
        </ul>
      </div>

      <main class="ps-main">
        <div class="inverse pt-80 pb-80">
          <div class="ps-products" data-mh="product-listing">
            <div class="ps-product__columns">
              <div
                v-for="product in apiProducts"
                :key="product"
                class="ps-product__column"
              >
                <Product :product="product" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<style></style>
