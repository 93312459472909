<script setup></script>
<template>
  <div class="ps-section--offer">
    <div class="ps-column">
      <a class="ps-offer">
        <img src="images/banner/showpage_first.jpg" alt="" />
      </a>
    </div>
    <div class="ps-column">
      <a class="ps-offer"
        ><img src="images/banner/showpage_second.jpg" alt=""
      /></a>
    </div>
  </div>
  <div
    class="ps-home-testimonial bg--parallax pb-80"
    data-background="images/utils/bottom_hero_min.jpg"
  >
    <div class="container">
      <div
        class="owl-slider"
        data-owl-auto="true"
        data-owl-loop="true"
        data-owl-speed="5000"
        data-owl-gap="0"
        data-owl-nav="false"
        data-owl-dots="true"
        data-owl-item="1"
        data-owl-item-xs="1"
        data-owl-item-sm="1"
        data-owl-item-md="1"
        data-owl-item-lg="1"
        data-owl-duration="1000"
        data-owl-mousedrag="on"
        data-owl-animate-in="fadeIn"
        data-owl-animate-out="fadeOut"
      >
        <div class="ps-testimonial">
          <div class="ps-testimonial__thumbnail">
            <img src="images/testimonial/founder_first.jpg" alt="" /><i
              class="fa fa-quote-left"
            ></i>
          </div>
          <header>
            <p>Stefania - Fundadora</p>
          </header>
          <footer>
            <p>
              “No necesitamos tantos zapatos para lucir increíbles, solo unos
              cuantos que con nuestra belleza nos lleven a muchas aventuras.“
            </p>
          </footer>
        </div>
        <div class="ps-testimonial">
          <div class="ps-testimonial__thumbnail">
            <img src="images/testimonial/founder_first.jpg" alt="" /><i
              class="fa fa-quote-left"
            ></i>
          </div>
          <header>
            <p>Stefania - Fundadora</p>
          </header>
          <footer>
            <p>
              “Detrás de cada gran mujer, existe una historia que la convirtió
              en guerrera.“
            </p>
          </footer>
        </div>
      </div>
    </div>
  </div>
</template>
